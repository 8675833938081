import { inject, Provider } from 'mobx-react';
import Head from 'next/head';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AppController from '../../base/App.controller';
import InfoSide from '../../components/common/Info/Info';
import CustomThemeProvider from '../../components/common/theme/CustomThemeProvider';
import SignInFormSide from '../../components/signin/SignInForm';
import SignInStore from '../../components/signin/SignInStore';

function Signin({ AppStore }) {
    const [Store] = useState(new SignInStore());
    const { t } = AppController.getTranslation(AppStore.scope, 'app');
    const isServer = typeof window === 'undefined';

    if (!isServer) {
        if (Router.query.errorCode) {
            Store.GenericErrorStore.openErrorModal(null, Router.query.errorCode);
            Router.replace(Router.route, Router.route, {
                shallow: true,
            });
        }
    }

    return (
        <Provider SignInStore={Store}>
            <Head>
                <title>{t('appTitle')}</title>
            </Head>
            <div className="signinContainer">
                <CustomThemeProvider alt>
                    <SignInFormSide />
                </CustomThemeProvider>
                <InfoSide page={'signin'} />
            </div>
        </Provider>
    );
}

Signin.propTypes = {
    AppStore: PropTypes.object.isRequired,
};

// not working for many cases and browsers.
Signin.getInitialProps = async ({ req }) => {
    const controller = new AppController(req);
    await controller.changeLanguage();

    return {
        namespacesRequired: controller.getNamespaces(['app', 'signin', 'signup']),
    };
};

export default inject('AppStore')(Signin);
