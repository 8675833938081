import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AppController from '../base/App.controller';
import { ADMIN } from '../constants/banks';
import Admin from './admin';
import Signin from './signin';

class MainPage extends Component {
    static async getInitialProps({ req, res }) {
        const controller = new AppController(req, res);
        await controller.changeLanguage();
        let groups;
        if (controller.scope === ADMIN) {
            const props = await controller.validateOktaSession();
            groups = props.groups;
        }

        return {
            groups,
            namespacesRequired: controller.getNamespaces(['app', 'admin']),
            scope: controller.scope,
        };
    }

    render() {
        const { scope, groups } = this.props;

        return scope === ADMIN ? <Admin groups={groups} /> : <Signin />;
    }
}

MainPage.propTypes = {
    scope: PropTypes.string.isRequired,
    groups: PropTypes.array,
};

export default MainPage;
