import { Checkbox, Chip, Divider, Grid } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import AppController from '../../base/App.controller';
import { TOKEN } from '../../constants/banks';
import Logo from '../common/logo/Logo';
import GenericErrorModal from '../common/modals/error/GenericError';
import PasswordField from '../common/passwordField/PasswordField';
import ButtonWrapper from '../common/wrappers/ButtonWrapper';
import FormWrapper from '../common/wrappers/FormWrapper';
import LinkWrapper from '../common/wrappers/LinkWrapper';
import TextFieldWrapper from '../common/wrappers/TextFieldWrapper';

const { publicRuntimeConfig } = require('next/config').default();

function SignInFormSide({ AppStore, SignInStore }) {
    const { t } = AppController.getTranslation(AppStore.scope, 'signin');
    const ref = React.createRef();
    const env = publicRuntimeConfig.features.prod ? 'production' : 'sandbox';
    const {
        form,
        updateField,
        isFormFilled,
        loading,
        handleSubmit,
        PasswordFieldStore,
    } = SignInStore;

    const [ctx, setCtx] = React.useState({
        isFormValid: false,
    });

    useEffect(() => {
        const isFormValid = isFormFilled && ref.current.checkValidity();

        setCtx({ isFormValid });
    }, Object.values(form));

    const _handleChange = (event) => {
        updateField(event.target.name, event.target.value);
    };

    const _handleCheck = (event) => {
        updateField(event.target.name, event.target.checked);
    };

    const _handleSubmit = () => {
        return handleSubmit(AppStore);
    };

    const resellerTypes = Object.keys(publicRuntimeConfig.resellers).map((reseller) => {
        return reseller;
    });

    const disabledEnvs = ['sandbox', 'prd'];

    return (
        <div className="SignInForm">
            <Grid container className="container">
                <Grid item xs={12} className={'signIpForm-flex-row'}>
                    <Logo className="logo" />
                    <Chip className={`chip ${env}`} label={env} />
                </Grid>
                <FormWrapper
                    className="form"
                    formRef={ref}
                    onSubmit={_handleSubmit}
                    isValid={ctx.isFormValid}>
                    <Grid container>
                        <Grid item xs={2} />
                        <Grid item container xs={8} spacing={4}>
                            <Grid item xs={12} className="formHeader">
                                {t('welcome')}
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldWrapper
                                    name="email"
                                    type="email"
                                    label={t('email')}
                                    value={form.email}
                                    onChange={_handleChange}
                                    required
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <PasswordField
                                    label={t('password')}
                                    value={form.password}
                                    onChange={_handleChange}
                                    dontShowReq
                                    PasswordFieldStore={PasswordFieldStore}
                                    required
                                />
                            </Grid>
                            <Grid item xs={7}>
                                <Checkbox
                                    checked={form.rememberMe}
                                    onChange={_handleCheck}
                                    name="rememberMe"
                                />
                                <span className={'rememberMe'}>{t('rememberMe')}</span>
                            </Grid>
                            <Grid item xs={5} className={'forgot'}>
                                <span>
                                    <LinkWrapper href="/forgot-password">
                                        {t('signin:forgotPassword')}
                                    </LinkWrapper>
                                </span>
                            </Grid>

                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={12}>
                                <ButtonWrapper
                                    loading={loading}
                                    className="submitButton"
                                    onClick={_handleSubmit}
                                    disabled={!ctx.isFormValid}>
                                    {t('signIn')}
                                </ButtonWrapper>
                            </Grid>
                            {!resellerTypes.includes(AppStore.scope) &&
                                (AppStore.scope !== TOKEN ||
                                    !disabledEnvs.includes(publicRuntimeConfig.tokenEnv)) && (
                                    <Grid item xs={12} className={'noAccount'}>
                                        <span>
                                            {t('noAccount')}&nbsp;
                                            <LinkWrapper href="/signup">
                                                {t('register')}
                                            </LinkWrapper>
                                        </span>
                                    </Grid>
                                )}
                        </Grid>
                        <Grid item xs={2} />
                    </Grid>
                </FormWrapper>
            </Grid>
            <GenericErrorModal Store={SignInStore.GenericErrorStore} />
        </div>
    );
}

SignInFormSide.propTypes = {
    AppStore: PropTypes.object.isRequired,
    SignInStore: PropTypes.object.isRequired,
};

export default inject('AppStore', 'SignInStore')(observer(SignInFormSide));
